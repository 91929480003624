<!--  -->
<template>
  <div class="recharge card">
    <div class="btt">
      <div class="btts"><span></span>资金明细</div>
    </div>
    <div class="bj">
      <div class="money">
        <div>账户余额</div>
        <p><span>¥</span>{{ chargelist.ext_cash }}</p>
      </div>
      <div class="money">
        <div>可用余额</div>
        <p><span>¥</span>{{ chargelist.ext_cash }}</p>
      </div>
      <div class="icon">
        <div class="capital-img">
          <div>冻结余额<img src="../../assets/member/dj.png" alt="" /></div>
          <p><span>¥</span>{{ chargelist.free_cash }}</p>
        </div>
        <div class="block" v-if="false">
          <p>账号租赁：¥0.00</p>
          <p>错误赔付：¥0.00</p>
          <p>保证金：¥0.00</p>
        </div>
      </div>
      <div class="btn">
        <img src="../../assets/member/cz-zjmx.png" alt="" />
        充值
      </div>
    </div>
    <div class="btt">
      <div class="btts"><span></span>账号充值</div>
    </div>
    <div v-if="isShow">
      <div class="contaner">
        <div class="title">
          <span>充值账号：</span>{{ chargelist.phonenum }}
        </div>
        <div class="title title-menoy">
          <span>充值金额：</span>
          <ul>
            <li
              :class="[index == currentMeony ? 'meActive' : '']"
              v-for="(item, index) in money"
              :key="index"
              @click="selsectMenoy(index)"
            >
              ¥{{ item }}
            </li>
          </ul>
          <div class="ul-input">
            <el-input
              placeholder="大于或等于5元"
              @focus="focusPrice($event)"
              @change="inpus"
              v-model.number="messageInput"
            ></el-input>
          </div>
        </div>
        <div class="title title-type">
          <span>充值方式：</span>
          <div class="zfb">
            <ul>
              <li
                :class="{ zhActive: currentColor == 0 }"
                @click="selsectType(0)"
              >
                <div class="li-img">
                  <img
                    src="../../assets/recharge/yxz.png"
                    alt=""
                    v-if="currentColor == 0"
                  />
                  <img src="../../assets/recharge/wxz.png" alt="" v-else />
                </div>
                <div class="zf">
                  <img src="../../assets/recharge/zfb.png" alt="" />
                </div>
              </li>
              <li
                :class="{ zhActive: currentColor == 1 }"
                @click="selsectType(1)"
              >
                <div class="li-img">
                  <img
                    src="../../assets/recharge/yxz.png"
                    alt=""
                    v-if="currentColor == 1"
                  />
                  <img src="../../assets/recharge/wxz.png" alt="" v-else />
                </div>
                <!-- <div class="zf"> -->
                <img src="../../assets/recharge/wx.png" alt="" />
                <!-- </div> -->
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="recharge-code" v-if="code">
        <div class="code-top">
          <div class="code-left">二维码：</div>
          <div class="code-right">
            <div class="code-img" v-loading="loading">
              <div id="qrCode" ref="qrCodeDiv"></div>
            </div>
            <p>请使用{{ paytext }}扫码支付</p>
            <p>
              如{{ paytext }}充值失败，您可以点击
              <span style="color: #fc3838" @click="clickTop">“刷新二维码”</span>
              再次尝试
            </p>
          </div>
        </div>
        <div class="code-bottom">
          去支付：<span>{{ total }}</span
          >元
        </div>
      </div>
      <el-button type="primary" round @click="submitTo">立即支付</el-button>
    </div>
    <div class="cuccess" v-else>
      <div class="success-top">
        <img src="../../assets/recharge/cg.png" alt="" />
        <p class="txt">充值成功</p>
        <p class="txet">
          充值金额： <span style="color: #fc3838">{{ total }}</span> 元
        </p>
      </div>
      <div>
        <el-button type="plany" round @click="clickPath"
          >再充一笔</el-button
        >
        <el-button
          type="primary"
          round
          @click="$router.push({ path: '/lobby' })"
          >前往租号</el-button
        >
      </div>
    </div>
  </div>
</template>
 
<script>
import QRCode from "qrcodejs2";

export default {
  name: "capital",
  data() {
    return {
      myInterval: null, //定时器
      isShow: true,
      loading: true, //loading 状态
      snum: 1,
      // 显示二维码
      code: false,
      messageInput: null,
      currentColor: 0,
      currentMeony: 1,
      // 余额
      chargelist: {},
      money: [],
      //  总
      total: 50,
      paytext: "支付宝",
      order_no: "",
    };
  },
  created() {
    this.orderQuery();
    this.Chargeinfo();
  },
  methods: {
    //    支付类型切换
    selsectType(id) {
      this.currentColor = id;
      this.code = false;
      this.loading = true;
      if (id == 1) {
        this.snum = 2;
        this.paytext = "微信";
      } else {
        this.snum = 1;
        this.paytext = "支付宝";
      }
    },
    selsectMenoy(id) {
      this.currentMeony = id;
      this.total = this.money[id];
      this.loading = true;
      this.code = false;
      this.messageInput = "";
    },
    focusPrice() {
      this.currentMeony = -1;
      this.code = false;
    },
    inpus() {
      this.total = this.messageInput;
    },
    // 支付按钮
    submitTo() {
      if (isNaN(this.total)) {
        this.$message.warning("金额格式不正确");
        return;
      }
      if (this.total <= 0) {
        this.$message.warning("请输入正确的充值金额");
        return;
      }
      if (this.total > 5000) {
        this.$message.warning("金额超过单次上限");
        return;
      }
      this.clickTop();
      this.code = true;
    },
    // 用户余额信息
    Chargeinfo() {
      this.$get("api/recharge", {}).then((res) => {
        if (res.code == 0) {
          this.chargelist = res.ret.uinfo;
          this.money = res.ret.ret_money;
        } else {
          if (res.code == 20026) {
            this.$router.push({
              path: "/login",
            });
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    clickTop() {
      this.loading = true;
      this.$post("api/recharge/pay", {
        amount: this.total,
        type: this.snum,
        channel_type: 3,
      }).then((res) => {
        if (res.code == 0) {
          var url = res.ret.qrCode;
          this.qrcode(url);
          this.loading = false;
          this.order_no = res.ret.order_no;
          //处理轮询查询订单
          this.myInterval = window.setInterval(() => {
            setTimeout(this.orderQuery(), 0);
          }, 2000);
        } else {
          this.$message.error(res.message);
          this.loading = false;
        }
      });
    },
    orderQuery() {
      this.$post("api/recharge/order_query", { order_no: this.order_no }).then(
        (res) => {
          if (res.code == 0) {
            if (res.ret) {
              //处理支付成功的逻辑
              this.isShow = false;
            }
          }else{
             clearInterval(this.myInterval)
          }
        }
      );
    },
    // 生成二维码
    qrcode(text) {
      document.getElementById("qrCode").innerHTML = "";
      setTimeout(() => {
        new QRCode(
          this.$refs.qrCodeDiv,
          {
            text: text,
            width: 120,
            height: 120,
            colorDark: "#333333",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H, // 容错率L/M/H
          },
          100
        );
      });
    },
    clickPath() {
      this.isShow = true
    }
  },
  watch: {
    isShow() {
      if(this.isShow == false) {
        clearInterval(this.myInterval)
      }
    }
  },
  // 页面切换
destroyed(){
  clearInterval(this.myInterval)
}
};
</script>
 
<style lang='scss' scoped >
.recharge {
  width: 968px;
  float: right;
  .btt {
    width: 100%;
    height: 65px;
    padding: 25px 35px 0;
    .btts {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      span {
        display: inline-block;
        width: 4px;
        height: 16px;
        background: #3c7efe;
        border-radius: 2px;
        margin-right: 10px;
      }
    }
  }
  .bj {
    margin: 0 auto 20px;
    width: 894px;
    height: 141px;
    border-radius: 4px;
    background: url("../../assets/member/bj-zjmx.png") no-repeat center;
    background-size: 100%;
    display: flex;
    align-items: center;
    position: relative;
    .money {
      width: 184px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #fffefe;
      border-right: 2px dashed #fffefe;
      padding: 20px 0 20px 35px;
      p {
        font-size: 30px;
        span {
          font-size: 14px;
          margin-right: 10px;
        }
      }
    }
    .icon {
      display: flex;
      .capital-img {
        width: 131px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fffefe;
        padding: 20px 0 20px 35px;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
          vertical-align: middle;
          margin: 0 0 3px 5px;
        }
        p {
          font-size: 30px;
          span {
            font-size: 14px;
            margin-right: 10px;
          }
        }
      }
      .block {
        width: 157px;
        height: 87px;
        background: #ffffff;
        border-radius: 12px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #83899d;
        padding-top: 15px;
        position: relative;
        p {
          text-align: center;
        }
        &::after {
          content: "";
          position: absolute;
          top: 19px;
          left: -7px;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-right: 7px solid #ebeef5;
          z-index: 10000;
        }
        &::before {
          content: "";
          position: absolute;
          top: 20px;
          left: -6px;
          width: 0;
          height: 0;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
          border-right: 6px solid #fff;
          z-index: 10001;
        }
      }
    }
    .btn {
      width: 140px;
      height: 49px;
      background: #ffffff;
      border-radius: 24px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #3c7efe;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 60px;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      img {
        width: 20px;
        height: 20px;
        margin-right: 3px;
      }
    }
  }
  .contaner {
    padding: 10px 35px 0;
    .title {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #848a9e;
      span {
        margin-right: 10px;
      }
    }
    .title-menoy {
      display: flex;
      align-items: center;
      margin-top: 30px;
      ul {
        display: flex;
        align-items: center;
        li {
          width: 105px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border: 1px solid #e1e4eb;
          border-radius: 6px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          margin-right: 6px;
          cursor: pointer;
          position: relative;
        }
      }
      .ul-input {
        width: 130px;
      }
    }
    .title-type {
      display: flex;
      align-items: center;
      margin: 30px 0;
      .zfb {
        ul {
          display: flex;
          align-items: center;
          li {
            width: 220px;
            height: 81px;
            background: #ffffff;
            border: 2px solid #e1e4eb;
            border-radius: 12px;
            margin-right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
              width: 150px;
              height: 65px;
            }
            .li-img {
              img {
                width: 18px;
                height: 18px;
              }
            }
            .zf {
              width: 150px;
              height: 65px;
              padding: 16px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
  .recharge-code {
    padding: 0 45px;
    .code-top {
      display: flex;
      .code-left {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #848a9e;
      }
      .code-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .code-img {
          width: 120px;
          height: 121px;
          border: 1px solid #e1e4eb;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        p {
          margin-top: 10px;
        }
      }
    }
    .code-bottom {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #848a9e;
      span {
        color: #000;
      }
    }
  }
  .el-button {
    margin: 26px 0 40px 40px;
  }
  .cuccess {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
    .success-top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      p {
        margin-top: 10px;
      }
      .txt {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
      }
      .txet {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #848a9e;
      }
    }
  }
}

//
.card {
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
  background-color: #fff;
  transition: 0.3s;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 10px;
}

//
.zhActive {
  border: 1px solid #3c7efe !important;
}

.meActive {
  border: 1px solid #3c7efe !important;
  color: #3c7efe !important;
  &::before {
    position: absolute;
    content: "";
    width: 30px;
    height: 30px;
    background: url("../../assets/recharge/czgx.png") no-repeat center;
    background-size: 100% 100%;
    bottom: -1px;
    right: -1px;
  }
}
</style>